import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import footerdata from '../../data/footerdata.json';

class Footer extends Component {

    componentDidMount() {
        let publicUrl = process.env.PUBLIC_URL+'/'

        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";
        var date = new Date();
        var year = date.getFullYear();
        document.getElementById('cYear').innerHTML = year;

        document.body.appendChild(minscript);
    }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgAlt = 'footer logo'

        return (
            <div>
                <footer className="footer-area footer-area-2">
                    <div className="container">
                        <div className="footer-widget-area mg-top-120">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="footer-widget widget">
                                        <div className="about_us_widget">
                                            <a href="/" className="footer-logo"> 
                                                <img src={publicUrl+footerdata.footerlogo} alt={imgAlt} />
                                            </a>
                                            <p>{footerdata.footertext}</p>      
                                            <ul className="social-icon">
                                                {footerdata.socialicon.map( ( item, i ) => 
                                                    <li key={i}>
                                                        <a className="facebook" href={item.url} target="_blank" rel="noopener noreferrer"><i className={item.icon}></i></a>
                                                    </li>
                                                )}
                                            </ul>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="footer-widget widget ">
                                        <h4 className="widget-title">{footerdata.contactwidget.title}</h4>
                                        <div className="contact_info_list">
                                            <p className="contact-content">{footerdata.contactwidget.address}</p>
                                            <p><span>Contact:</span> {footerdata.contactwidget.contact}</p>
                                            <p><span>E-mail:</span> {footerdata.contactwidget.email}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-5 col-xs-2">
                                    <div className="footer-widget widget ">
                                        <h4 className="widget-title">Whatsapp</h4>
                                        <div className="share-button mt-15">
                                            <a className="share-button share-button-whatsapp" href="/#" target="_blank">
                                                <i className="fa fa-whatsapp"></i>
                                                <span>Whatsapp</span>
                                            </a>                                   
                                        </div>
                                    </div>
                                </div>


                                
                                
                            </div>
                        </div>
                    </div>
                    <div className="copyright-inner">
                        <div className="copyright-text"> &copy; Nutribe <span id= "cYear"></span> Todos los derechos reservados. <br></br> Puede consultar la <a href= "https://cloud.nutribe.io/politica-privacidad" target="_blank" rel="noopener noreferrer"><span> Política de Privacidad</span></a> y los <a href= "https://cloud.nutribe.io/terminos-y-condiciones" target="_blank" rel="noopener noreferrer"><span>Términos y Condiciones</span></a> en cualquier momento. </div>
                        <div className="copyright-text"> Fuentes bibliográficas: <a href= "assets/doc/Referencias bibliográficas NutriBe.pdf" target="_blank"><span> NutriBe.</span></a></div>
                    </div>
                </footer>
                <div className="back-to-top">
                    <span className="back-top"><i className="fa fa-angle-up"></i></span>
                </div>
            </div>
        )
    }
}


export default Footer