import React from 'react';
import Navbar from './global-components/navbar';
import Slider from './section-components/slider';
import Services from './section-components/services';
import PricingTable from './section-components/pricingtable';
import SoftBoxManage from './section-components/soft-box-manage';
import About from './section-components/about';
import Screenshot from './section-components/screenshot';
import TestimonialV2 from './section-components/testimonial';
import Contacto from './section-components/contacto';
import Footer from './global-components/footer';

const Home = () => {
    return <div>
        <Navbar />
        <Slider />
        <Services />
        <About />
        <SoftBoxManage />
        <Screenshot />
        <PricingTable />
        <TestimonialV2 />
        <Contacto />
        <Footer />
    </div>
}

export default Home