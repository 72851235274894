import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, BrowserRouter, Route, Switch } from "react-router-dom";
import HomeV2 from './components/Nutribe';
import ReactGA from 'react-ga';


class Root extends Component {
    render() {
        return(
            <Router>
                <BrowserRouter basename="/">
                <div>
                <Switch>
                    <Route exact path="/" component={HomeV2} />
                    </Switch>
                </div>
                </BrowserRouter>
            </Router>
        )
    }
}

export default Root;


const TRACKING_ID = "G-MJHF4M5W4Z";
ReactGA.initialize(TRACKING_ID);

ReactDOM.render(<Root />, document.getElementById('riyaqas'));
